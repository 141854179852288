<template>
  <v-data-iterator
    :items="items"
    sort-by="id_str"
    :search="search"
    :sort-desc="sortDesc"
    :footer-props="{ 'items-per-page-options': [20, 30, 40] }"
    :items-per-page="20"
    :page="page"
    :options="options"
    @options="optionsSave"
    @update:page="scrollTop"
  >
    <template v-slot:header>
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('message.search_from_featured_tweets')"
        />
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-spacer />
          <v-btn-toggle
            v-model="sortDesc"
            mandatory
          >
            <v-btn
              large
              depressed
              color="gray"
              :value="false"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn
              large
              depressed
              color="gray"
              :value="true"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-toolbar>
      <v-chip-group>
        <v-chip
          v-for="(house_size, index) in SizeArray"
          :key="index"
          dark
          color="#e91e63"
          @click="updateItems(house_size)"
        >
          {{ house_size }}
        </v-chip>
      </v-chip-group>
    </template>

    <template v-slot:default="props">
      <!-- <v-select
        v-model="selectedId"
        item-text="user.name"
        item-value="user.name"
        :items="items"
        label="ユーザーで絞る"
        v-on:change="changeUser(selectedId)"
      /> -->
      <!-- v-touch="{
          left: () => $router.go(1),
          right: () => $router.push(-1)
        }" -->
      <v-row
        dense
      >
        <v-col
          v-for="item in props.items"
          :key="item.id_str"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-container
            class="pa-2 max-width"
          >
            <tweet
              :id="item.id_str"
              error-message=" "
              :options="{ align: 'center' }"
              @TweetError="notShow(item)"
            >
              <spinner />
            </tweet>
            <v-chip
              v-if="item.aetheryteId"
              class="pa-3 mx-1"
            >
              {{ aetheryte(item.aetheryteId) }}
            </v-chip>
            <v-chip
              v-if="item.size"
              class="pa-3 mx-1"
            >
              {{ item.size }}
            </v-chip>
          </v-container>
        </v-col>
        <!-- <ads /> -->
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import axios from 'axios'
  import Spinner from 'vue-simple-spinner'
  // import Ads from './Ads'
  export default {
    name: 'TweetView',
    metaInfo: {
      title: 'HighProfile',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    components: {
      Tweet,
      Spinner,
      // Ads,
    },
    data: function () {
      return {
        items: [],
        rawItems: [],
        // selectedId: "",
        sortDesc: true,
        search: '',
        options: {},
        page: 1,
        json_items: [],
        SizeArray: ['All', 'S', 'M', 'L', 'apartment', 'FC room'],
      }
    },
    created: async function () {
      await axios.get(`../../../aetheryte.json?timestamp=${new Date().getTime()}`).then(res => {
        res.data.forEach(data => {
          this.json_items.push(data)
        })
      })

      if (!this.$store.highProfileItems) {
        await axios.get(`../../high_profile_tweet.json?timestamp=${new Date().getTime()}`).then(res => {
          this.items = res.data
          this.rawItems = res.data
          this.$store.highProfileItems = this.items
          // res.data.forEach(data => {
          //   let inputdata;
          //   inputdata = data.quoted_status
          //   inputdata.user_name = data.quoted_status.user.name;
          //   inputdata.user_screen_name = data.quoted_status.user.screen_name;
          //   inputdata.user_id_str = data.quoted_status.user.id_str;
          //   this.items.push(inputdata);
          // });
        })
      } else {
        this.items = this.$store.highProfileItems
        this.rawItems = this.$store.highProfileItems
      }

      if (this.$store.highProfileOptions) {
        this.options = this.$store.highProfileOptions
      }
      // console.log(this.$store.page)
      this.page = this.$store.highProfilePage
    },
    methods: {
      scrollTop: function () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.$store.highProfilePage = event
      },
      // 削除済みツイートの排除
      notShow: function (item) {
        // 指定されたindexの要素を1つ削除します。
        // this.items.splice(index, 1)
        var result = this.items.filter(function (i) {
          return i !== item
        })
        this.items = result
      },
      optionsSave: function (event) {
        // console.log(event)
        this.$store.highProfileOptions = event
      },
      aetheryte: function (text) {
        for (var key of Object.keys(this.json_items)) {
          // 連想配列のキーと配列の値が一致するか検索
          // console.log(this.json_items[key].AreaNameEn)
          if (this.json_items[key].AetheryteId === text) {
            switch (this.$i18n.locale) {
              case 'ja':
                return this.json_items[key].AetheryteJp
              case 'en':
                return this.json_items[key].AetheryteEn
              case 'ko':
                return this.json_items[key].AetheryteKo
              case 'zh-CN':
                return this.json_items[key].AetheryteCHS
              case 'zh-TW':
                return this.json_items[key].AetheryteCHT
            }
          }
        }
      },
      size: function (text) {
        for (var key of Object.keys(this.json_items)) {
          // 連想配列のキーと配列の値が一致するか検索
          // console.log(this.json_items[key].AreaNameEn)
          if (this.json_items[key].AetheryteId === text) {
            return this.json_items[key].Size
          }
        }
      },
      updateItems (houseSize) {
        if (houseSize !== 'All') {
          this.items = this.rawItems.filter(item => item.size === houseSize)
        } else { this.items = this.rawItems }
      },
    },
    // methods: {
    //   changeUser(selectedId) {
    //     //this.$router.push({path: a })
    //     console.log(selectedId);
    //   }
    // }
  }
</script>
